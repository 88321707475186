export const SPECS = {
  CustomProductUrlInCartOOI: 'specs.stores.CustomProductUrlInCartOOI',
  CashierExpressNavigateToThankYouPageWhen1ShippingMethod:
    'specs.stores.CashierExpressNavigateToThankYouPageWhen1ShippingMethod',
  DisplayPickupInTotalSummary: 'specs.stores.DisplayPickupInTotalSummary2',
  MinimumOrderStorefront: 'specs.stores.MinimumOrderStorefront',
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  PaypalUpdateShippingAndBilling: 'specs.stores.PaypalUpdateShippingAndBilling',
  ApplePayDebugNullOrderId: 'specs.stores.ApplePayDebugNullOrderId',
} as const;
